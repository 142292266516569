import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemAuth extends BaseApiPiem {
  changePassword(data, params) {
    return this.post(`/auth/change-password/${params}`, data);
  }

  login(data) {
    return this.post("/auth/login", data);
  }

  requestPasswordChange(data) {
    return this.post("/auth/request-password-change", data);
  }
}

export const apiPiemAuth = new ApiPiemAuth();
