import store from "@/store";
import { ICONS } from "@/utils";
const menuList = {
  menuDashboard: {
    icon: ICONS.DASHBOARD,
    title: "Visão Geral",
    active: true,
    link: "/dashboard",
    class: "piem-menu-item-link",
    external: false,
    key: "menuDashboard",
    order: 1,
  },
  menuClients: {
    icon: ICONS.CLIENT,
    title: "Clientes",
    active: true,
    link: "/clients",
    class: "piem-menu-item-link",
    external: false,
    key: "menuClients",
    order: 2,
  },
  menuPowerPlants: {
    icon: ICONS.POWER_PLANT,
    title: "Usinas",
    active: true,
    link: "/powerplants",
    class: "piem-menu-item-link",
    external: false,
    key: "menuPowerPlants",
    order: 3,
  },
  menuDevices: {
    icon: ICONS.DEVICE,
    title: "Dispositivos",
    active: true,
    link: "/devices",
    class: "piem-menu-item-link",
    external: false,
    key: "menuDevices",
    order: 4,
  },
  // company: {
  //   icon:
  //     '<svg width="12px" height="12px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H12V4.5H15V15H7.5V10.5H0V0ZM10.5 4.5V1.5H1.5V9H7.5V4.5H10.5ZM6 3H3V4.5H6V3ZM13.5 13.5V6H9V13.5H13.5ZM10.5 10.5H12V12H10.5V10.5ZM3 6H6V7.5H3V6Z" fill="#344767"/></svg>',
  //   title: "Empresas",
  //   active: true,
  //   link: "/companies",
  //   class: "piem-menu-item-link",
  //   external: false,
  //   key: "company",
  // },
  // consumerUnit: {
  //   icon:
  //     '<svg width="12px" height="12px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H12V4.5H15V15H7.5V10.5H0V0ZM10.5 4.5V1.5H1.5V9H7.5V4.5H10.5ZM6 3H3V4.5H6V3ZM13.5 13.5V6H9V13.5H13.5ZM10.5 10.5H12V12H10.5V10.5ZM3 6H6V7.5H3V6Z" fill="#344767"/></svg>',
  //   title: "Unidades consumidoras",
  //   active: true,
  //   link: "/consumerunits",
  //   class: "piem-menu-item-link",
  //   external: false,
  //   key: "consumerUnit",
  // },
};
const submenuList = {
  menuPieta: {
    icon: ICONS.ADMIN,
    active: false,
    title: "Ambiente Pieta",
    class: "piem-menu-item-link",
    key: "menuPieta",
    items: [
      {
        title: "Empresas Integradoras",
        link: "/companies",
        active: false,
        class: "piem-menu-item-link",
        prefix: "U",
      },
      {
        title: "Usuários Integradores",
        link: "/integrator-users",
        active: false,
        class: "piem-menu-item-link",
        prefix: "U",
      },
      {
        title: "Cliente Final",
        link: "/finalClients",
        active: false,
        class: "piem-menu-item-link",
        prefix: "U",
      },
      {
        title: "Administradores",
        link: "/admins",
        active: false,
        class: "piem-menu-item-link",
        prefix: "U",
      },
      {
        title: "Papéis",
        link: "/roles",
        active: false,
        class: "piem-menu-item-link",
        prefix: "U",
      },
    ],
  },
  menuSettingsIntegrator: {
    icon: ICONS.ADMIN,
    active: false,
    title: "Configurações",
    class: "piem-menu-item-link",
    key: "menuSettingsIntegrator",
    items: [
      {
        title: "Alertas",
        link: "/settings/alerts",
        active: false,
        class: "piem-menu-item-link",
        prefix: "U",
      },
    ],
  },
};
export const menuDrawer = (menuType) => {
  const menu = menuType === "menu" ? menuList : submenuList;
  const finalMenu = [];
  const permissions = store.getters["user/getPermissions"];
  for (const permission of permissions) {
    if (menu[permission.name]) finalMenu.push(menu[permission.name]);
  }

  if (menuType === "menu") {
    finalMenu.sort((a, b) => a.order - b.order);
  }
  return finalMenu;
};
