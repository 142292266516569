import axios from "axios";

class ApiIbge {
  constructor() {
    this.instance = axios.create({
      baseURL: "https://servicodados.ibge.gov.br/api/v1/localidades/estados",
    });
  }

  async getStates() {
    try {
      const response = await this.instance.get("?orderBy=nome");
      if (response?.status === 200 && response.data) {
        return {
          success: true,
          data: response?.data?.map((state) => {
            return {
              text: state?.nome,
              value: state?.sigla,
            };
          }),
        };
      }
      return { success: false, data: null };
    } catch (error) {
      console.error(error);
      return { success: false, data: null };
    }
  }

  async getCitiesByState(state) {
    try {
      if (!state) return [];
      const response = await this.instance.get(`/${state}/municipios`);
      if (response?.status === 200 && response.data) {
        return {
          success: true,
          data: response?.data?.map((city) => {
            return {
              text: city?.nome,
              value: city?.nome,
            };
          }),
        };
      }
      return { success: false, data: null };
    } catch (error) {
      console.error(error);
      return { success: false, data: null };
    }
  }
}

export const API_IBGE = new ApiIbge();
