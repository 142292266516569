import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemDashboard extends BaseApiPiem {
  getHomeDashboard() {
    return this.get("/dashboard");
  }

  getPowerPlantDashboard(params) {
    return this.get(`/dashboard/get-powerPlant-dashboard/${params}`);
  }
}

export const apiPiemDashboard = new ApiPiemDashboard();
