export default {
  _id: null,
  name: null,
  surname: null,
  email: null,
  cpf: null,
  cnpj: null,
  cellphone: null,
  powerPlants: [],
  consumerUnits: [],
  devices: [],
  company: null,
};
