export const ICONS = {
  ADMIN: "$admin",
  ANGLE_DOWN: "$angleDown",
  ARROW_CHEVRON_RIGHT: "$arrowChevronRight",
  CAPACITATION: "$capacitation",
  CHECK: "$check",
  CLIENT: "$client",
  CLOSE: "$close",
  CONSULTANCY: "$consultancy",
  CONSUMER_UNIT: "$consumerunit",
  DASHBOARD: "$dashboard",
  DEVICE: "$device",
  DELETE: "$delete",
  EDIT: "$edit",
  EYE: "$eye",
  EYE_SLASH: "$eyeSlash",
  FILE: "$file",
  FINANCING: "$financing",
  GENERAL: "$general",
  LEFT: "$left",
  PHOTOVOLTAIC_KIT_SEARCH: "$photovoltaicKitSearch",
  POWER_PLANT: "$powerplant",
  POWER_PLANT_OUTLINE: "$powerPlantOutline",
  PLUS: "$plus",
  RIGHT: "$right",
  SEARCH: "$search",
  USER: "$user",
  VIEW: "$view",
  SHARE: "$share",
};
