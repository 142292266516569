export default {
  _id: null,
  client: null,
  finalClient: null,
  company: null,
  energyDistributor: null,
  address: {
    cep: null,
    state: null,
    city: null,
    neighborhood: null,
    street: null,
    number: null,
    complement: null,
  },
  name: null,
  power: null,
  price: null,
  status: null,
  health: null,
  installDate: null,
  installer: null,

  annualGenerationAgreed: null,
  generation: {
    annualValueType: null,
    monthyValuesType: null,
    annualValue: null,
    monthlyValues: null,
  },

  devices: [],
};
