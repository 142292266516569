<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="px-0 mx-6 toolbar-content-padding-y-none"
    flat
  >
    <v-row class="py-1">
      <v-col
        cols="12"
        sm="12"
        class="d-flex piem-breadcrumbs-h6"
        align-content="center"
        align-items="center"
      >
        <div>
          <h6 class="mb-0 piem-breadcrumbs-h6">
            {{ $route.name }}
          </h6>
        </div>
        <div
          v-if="isMobileBreakpoint"
          class="drawer-toggler pa-5 ms-6 cursor-pointer"
          :class="{ active: togglerActive }"
          @click="toggleMenu"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>
        <v-spacer />
        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="199"
          max-width="199"
          max-height="150"
          z-index="10"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              class="text-body"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
              small
              @click="toggleVisibile"
            >
              <v-icon size="16">{{ ICONS.USER }}</v-icon>
            </v-btn>
          </template>

          <v-card-text style="padding: 0">
            <v-list class="py-0 piem-border-menu">
              <v-list-item
                v-for="(item, i) in dropdown"
                :key="i"
                class="pa-1 piem-list-item-hover-active"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    <v-row>
                      <v-col>
                        <span
                          @click="clickMenu(item.action)"
                          class="piem-center piem-absolute piem-text-menu"
                          >{{ item.title }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import { mapActions } from "vuex";
import { ICONS } from "@/utils";
export default {
  name: "AppBar",
  props: {
    background: { type: String },
    linkColor: { type: String },
    menu: { type: Boolean },
    miniMenu: { type: Boolean },
  },
  data() {
    return {
      ICONS,
      dropdown: [
        {
          title: "Meus Dados",
          action: "me",
        },
        {
          title: "Termos de uso",
          action: "term-of-use",
        },
        {
          title: "Sair",
          action: "logout",
        },
      ],
    };
  },

  computed: {
    togglerActive() {
      if (this.isMobileBreakpoint) return !this.menu;
      return this.miniMenu;
    },

    isMobileBreakpoint() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    ...mapActions("user", { logout: "logout" }),

    async clickMenu(item) {
      this.$emit("overlayMenu", false);
      switch (item) {
        case "me":
          this.$router.push("/me");
          break;
        case "term-of-use":
          this.$router.push("/term-of-use");
          break;
        default:
          await this.logout();
          break;
      }
    },

    toggleVisibile() {
      this.$emit("overlayMenu");
    },

    toggleMenu() {
      this.$emit("toggleMenu");

      const body = document.getElementsByTagName("body")[0];
      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
  },
};
</script>
<style>
#btn-logout {
  color: #fff;
  font-weight: bold;
}

.btn-white {
  color: #fff !important;
}
</style>
