import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemSettingsIntegrator extends BaseApiPiem {
  getItem() {
    return this.get("/settingsintegrator");
  }

  patchItem(data) {
    return this.patch("/settingsintegrator", data);
  }
}

export const apiPiemSettingsIntegrator = new ApiPiemSettingsIntegrator();
