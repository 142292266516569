import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemUser extends BaseApiPiem {
  getMe() {
    return this.get("/user/me");
  }

  getById(params) {
    return this.get(`/user/by-id/${params}`);
  }

  create(data) {
    return this.post("/user", data);
  }

  update(data, params) {
    return this.put(`/user/${params}`, data);
  }

  remove(params) {
    return this.delete(`/user/${params}`);
  }
}

export const apiPiemUser = new ApiPiemUser();
