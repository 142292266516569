export const getUrlSearchParamsString = ({
  page,
  itemsPerPage,
  sortBy,
  sortDesc,
  search,
}) => {
  const result = Object.assign(
    {},
    {
      page,
      itemsPerPage,
    }
  );
  if (sortBy?.length) {
    Object.assign(result, {
      sortBy,
      sortDesc,
    });
  }
  if (search) {
    for (const [key, value] of Object.entries(search)) {
      Object.assign(result, { [key]: value });
    }
  }

  return new URLSearchParams(result).toString();
};
