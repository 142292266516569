const getEnvironmentVariable = (type, fallback) => {
  return window.config?.[type] || process.env?.[type] || fallback || "";
};

export const environmentVariables = {
  backend_url: getEnvironmentVariable("VUE_APP_URL", "http://localhost:3000/"),
};

export default {
  install: (Vue) => {
    Vue.prototype.$environmentVariables = environmentVariables;
  },
};
