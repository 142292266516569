import Vue from "vue";
import VueRouter from "vue-router";
import { companyRegisterPage } from "@/router/routes/CompanyRegisterPage";
import { loginPage } from "@/router/routes/LoginPage";
import { changePasswordPage } from "@/router/routes/ChangePasswordPage";
import { privatePages } from "@/router/routes/PrivatePages";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  companyRegisterPage,
  loginPage,
  changePasswordPage,
  privatePages,
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("routeHistory/setLastRoute", from);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["user/isAuthenticated"]) {
      next();
    } else if (await store.dispatch("user/isLogged")) {
      next();
    } else {
      await store.dispatch("user/logout");
    }
  } else {
    next();
  }
});
export default router;
