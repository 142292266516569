const state = {
  lastRoute: null,
};
const mutations = {
  SET_LAST_ROUTE: (state, route) => Object.assign(state, { lastRoute: route }),
};
const actions = {
  setLastRoute: ({ commit }, route) => commit("SET_LAST_ROUTE", route),
};

const getters = {
  getLastRoute: (state) => state.lastRoute,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
