import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemCompany extends BaseApiPiem {
  getAll(params) {
    return this.get(`/companies/all/${params}`);
  }

  getById(params) {
    return this.get(`/companies/by-id/${params}`);
  }

  validateCNPJ(params) {
    return this.get(`/companies/validate/cnpj/${params}`);
  }

  update(data, params) {
    return this.put(`/companies/${params}`, data);
  }

  remove(params) {
    return this.delete(`/companies/${params}`);
  }
}

export const apiPiemCompany = new ApiPiemCompany();
