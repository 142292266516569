import state from "@/store/modules/client/state.js";
import mutations from "@/store/modules/client/mutations.js";
import actions from "@/store/modules/client/actions.js";
import getters from "@/store/modules/client/getters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
