<template>
  <v-container>
    <v-overlay
      :value="true"
      absolute
      color="#222830"
      opacity="0.8"
      z-index="1000"
    >
      <transition appear name="fade">
        <v-progress-circular indeterminate class="cl-color3" />
      </transition>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "Loading",
};
</script>
