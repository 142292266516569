import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemDevice extends BaseApiPiem {
  getAll(params) {
    return this.get(`/device/all/${params}`);
  }

  getDetails(params) {
    return this.get(`/device/details/${params}`);
  }

  getList() {
    return this.get("/device/list/");
  }

  getById(params) {
    return this.get(`/device/by-id/${params}`);
  }

  getAvailable(params) {
    return this.get(`/device/get-available-devices/${params}`);
  }

  create(data) {
    return this.post("/device/", data);
  }

  update(data, params) {
    return this.put(`/device/${params}`, data);
  }

  updatePowerPlant(data, params) {
    return this.put(`/device/update-powerplant/${params}`, data);
  }

  remove(params) {
    return this.delete(`/device/${params}`);
  }
}

export const apiPiemDevice = new ApiPiemDevice();
