/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/es5/locale/pt";
import "@fortawesome/fontawesome-free/css/all.css";
import Admin from "@/assets/svg/icons/Admin.vue";
import Client from "@/assets/svg/icons/Client.vue";
import ConsumerUnit from "@/assets/svg/icons/ConsumerUnit.vue";
import Dashboard from "@/assets/svg/icons/Dashboard.vue";
import Device from "@/assets/svg/icons/Device.vue";
import General from "@/assets/svg/icons/General.vue";
import PowerPlant from "@/assets/svg/icons/PowerPlant.vue";
import PhotovoltaicKitSearch from "@/assets/svg/icons/PhotovoltaicKitSearch.vue";
import Financing from "@/assets/svg/icons/Financing.vue";
import PowerPlantOutline from "@/assets/svg/icons/PowerPlantOutline.vue";
import Capacitation from "@/assets/svg/icons/Capacitation.vue";
import Consultancy from "@/assets/svg/icons/Consultancy.vue";
import ArrowChevronRight from "@/assets/svg/icons/ArrowChevronRight.vue";
import File from "@/assets/svg/icons/File.vue";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "fa",
    values: {
      delete: "fa fa-trash",
      view: "fa fa-eye",
      search: "fas fa-search",
      left: "fa fa-angle-left",
      right: "fa fa-angle-right",
      edit: "fa fa-edit",
      eye: "fa-eye",
      eyeSlash: "fa-eye-slash",
      user: "fa fa-user",
      angleDown: "fas fa-angle-down",
      close: "fa fa-times",
      check: "fa fa-check",
      share: "fa fa-share-alt",
      admin: {
        component: Admin,
      },
      client: {
        component: Client,
      },
      consumerunit: {
        component: ConsumerUnit,
      },
      dashboard: {
        component: Dashboard,
      },
      device: {
        component: Device,
      },
      general: {
        component: General,
      },
      powerplant: {
        component: PowerPlant,
      },
      photovoltaicKitSearch: {
        component: PhotovoltaicKitSearch,
      },
      financing: {
        component: Financing,
      },
      powerPlantOutline: {
        component: PowerPlantOutline,
      },
      capacitation: {
        component: Capacitation,
      },
      consultancy: {
        component: Consultancy,
      },
      arrowChevronRight: {
        component: ArrowChevronRight,
      },
      file: {
        component: File,
      },
    },
  },
});
