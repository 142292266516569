const changePassword = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/pages/changePassword/ChangePassword.vue"
  );
export const changePasswordPage = {
  path: "/change-password/:token",
  component: changePassword,
  name: "PIEM - Reescrever senha",
  meta: {
    requiresAuth: false,
  },
};
