const login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/pages/login/Login.vue");
export const loginPage = {
  path: "/login",
  component: login,
  name: "PIEM - Login",
  meta: {
    requiresAuth: false,
  },
};
