import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemClient extends BaseApiPiem {
  getAll(params) {
    return this.get(`/client/all/${params}`);
  }

  getList() {
    return this.get("/client/list/");
  }

  getById(params) {
    return this.get(`/client/by-id/${params}`);
  }

  getDetails(params) {
    return this.get(`/client/details/${params}`);
  }

  create(data) {
    return this.post("/client/", data);
  }

  update(data, params) {
    return this.put(`/client/${params}`, data);
  }

  remove(params) {
    return this.delete(`/client/${params}`);
  }
}

export const apiPiemClient = new ApiPiemClient();
