import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemIntegrator extends BaseApiPiem {
  getAll(params) {
    return this.get(`/integrator/all/${params}`);
  }

  createWithoutAuthentication(data) {
    return this.post("/integrator/createWithoutAuthentication", data);
  }

  createCompany(data) {
    return this.post("/integrator/createCompany", data);
  }
}

export const apiPiemIntegrator = new ApiPiemIntegrator();
