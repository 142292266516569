import { SET_CLIENT, RESET_CLIENT } from "@/store/modules/client/contracts";
import { apiPiemClient } from "@/services";

export default {
  async readClient(context, payload) {
    const { id } = payload;
    const response = await apiPiemClient.getDetails(id);
    if (response?.status === 200 && response?.data) {
      context.commit(RESET_CLIENT);
      context.commit(SET_CLIENT, response.data);
    }
  },

  async createClient(context, payload) {
    const response = await apiPiemClient.create(payload);
    if (response?.status === 201 && response?.data) {
      context.commit(RESET_CLIENT);
      context.commit(SET_CLIENT, response.data);
    }
    return response;
  },

  async updateClient(context, payload) {
    const { id, data } = payload;
    const response = await apiPiemClient.update(data, id);
    if (response?.status === 200 && response?.data) {
      context.commit(RESET_CLIENT);
      context.commit(SET_CLIENT, response.data);
    }
    return response;
  },
};
