<template>
  <v-app>
    <router-view></router-view>
    <Loading v-if="loading" />
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/loading/Loading.vue";
export default {
  name: "App",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters("loading", { loading: "getLoading" }),
  },
};
</script>
