import { apiPiemPowerPlant } from "@/services";
import {
  SET_POWER_PLANT,
  RESET_POWER_PLANT,
} from "@/store/modules/powerPlant/contracts";

export default {
  async readPowerPlant(context, payload) {
    const { id } = payload;
    const response = await apiPiemPowerPlant.getById(id);
    if (response?.status === 200 && response?.data) {
      context.commit(RESET_POWER_PLANT);
      context.commit(SET_POWER_PLANT, {
        ...response.data.powerPlant,
        devices: response.data.devices,
      });
    }
  },

  async createPowerPlant(context, payload) {
    const response = await apiPiemPowerPlant.create(payload);
    if (response?.status === 201 && response?.data) {
      context.commit(RESET_POWER_PLANT);
      context.commit(SET_POWER_PLANT, response.data);
    }
    return response;
  },

  async updatePowerPlant(context, payload) {
    const { id, data } = payload;
    const response = await apiPiemPowerPlant.update(data, id);
    if (response?.status === 200 && response?.data) {
      context.commit(RESET_POWER_PLANT);
      context.commit(SET_POWER_PLANT, response.data);
    }
    return response;
  },
};
