import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemFinalClient extends BaseApiPiem {
  getAll(params) {
    return this.get(`/finalClient/all/${params}`);
  }

  createWithoutAuthentication(data) {
    return this.post("/finalClient/createWithoutAuthentication/", data);
  }
}

export const apiPiemFinalClient = new ApiPiemFinalClient();
