import { SET_USER, REMOVE_USER } from "@/store/modules/user/contracts";
import router from "@/router";
import { apiPiemUser } from "@/services";
import { Storage, ACCESS_TOKEN } from "@/localStorage";

export default {
  async login(context, payload) {
    await Storage.setItem(ACCESS_TOKEN, payload.access_token);
    context.commit(SET_USER, { accessToken: payload.access_token });
    const ret = await apiPiemUser.getMe();
    if (ret?.status === 200 && ret?.data) {
      context.commit(SET_USER, ret.data);
      await router.push({ name: "Visão geral" });
    }
  },

  async isLogged(context) {
    const accessToken = await Storage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      context.commit(SET_USER, { accessToken });
      const ret = await apiPiemUser.getMe();
      if (ret?.status === 200 && ret?.data) {
        context.commit(SET_USER, ret.data);
        return true;
      }
    }
    return false;
  },

  async logout(context) {
    await Storage.removeItem(ACCESS_TOKEN);
    context.commit(REMOVE_USER);
    await router.push({ name: "PIEM - Login" });
  },
};
