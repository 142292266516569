/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import ScssPlugin from "./plugins/scss-plugin";
import store from "./store";

Vue.config.productionTip = false;

// VueMask - mask library
import VueMask from "v-mask";
Vue.use(VueMask);

// plugin setup
Vue.use(ScssPlugin);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
