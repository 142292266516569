import state from "@/store/modules/loading/state.js";
import mutations from "@/store/modules/loading/mutations.js";
import getters from "@/store/modules/loading/getters.js";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
