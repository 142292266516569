import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user";
import client from "@/store/modules/client";
import powerPlant from "@/store/modules/powerPlant";
import loading from "@/store/modules/loading";
import routeHistory from "@/store/modules/routeHistory";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    client,
    powerPlant,
    loading,
    routeHistory,
  },
});
