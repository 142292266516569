import { USER_TYPES } from "@/utils";

export default {
  getUser: (state) => state,
  getPermissions: (state) => state.role.permissions,
  getAccessToken: (state) => state.accessToken,
  isAuthenticated: (state) => !!state.accessToken,
  isIntegrator: (state) => state.type === USER_TYPES.INTEGRATOR,
  isPietaAdmin: (state) => state.type === USER_TYPES.PIETA,
  isFinalClient: (state) => state.type === USER_TYPES.FINAL_CLIENT,
};
