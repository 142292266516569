<template>
  <v-app>
    <Drawer
      :menu="menu"
      :miniMenu="miniMenu"
      @openHideMenu="openHideMenu"
      @openHideMenuMini="openHideMenuMini"
    />
    <v-main class="ma-2">
      <AppBar
        background="bg-transparent"
        :menu="menu"
        :miniMenu="miniMenu"
        @toggleMenu="toggleMenu"
        @overlayMenu="menuOverlay"
      />
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <router-view></router-view>
      </fade-transition>
    </v-main>

    <v-overlay
      z-index="9"
      :value="overlayVisible"
      absolute
      color="#222830"
      opacity="0.5"
      @click.native="menuOverlay"
    ></v-overlay>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: {
    FadeTransition,
    Drawer,
    AppBar,
  },
  data() {
    return {
      menu: null,
      miniMenu: false,
      overlayVisible: false,
    };
  },

  methods: {
    menuOverlay() {
      this.overlayVisible = !this.overlayVisible;
    },

    openHideMenu(value) {
      this.menu = value;
    },

    openHideMenuMini(value) {
      this.miniMenu = value;
    },

    toggleMenu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          this.menu = !this.menu;
          break;
        default:
          this.miniMenu = !this.miniMenu;
          break;
      }
    },
  },
};
</script>
<style lang="scss"></style>
