import {
  SET_POWER_PLANT,
  RESET_POWER_PLANT,
} from "@/store/modules/powerPlant/contracts";

export default {
  [SET_POWER_PLANT](state, payload) {
    Object.assign(state, payload);
  },
  [RESET_POWER_PLANT](state) {
    Object.assign(state, {
      _id: null,
      client: null,
      company: null,
      energyDistributor: null,
      finalClient: null,
      address: {
        cep: null,
        state: null,
        city: null,
        neighborhood: null,
        street: null,
        number: null,
        complement: null,
      },
      name: null,
      power: null,
      price: null,
      status: null,
      health: null,
      installDate: null,
      installer: null,

      annualGenerationAgreed: null,

      generation: {
        annualValueType: null,
        monthyValuesType: null,
        annualValue: null,
        monthlyValues: null,
      },

      devices: [],
    });
  },
};
