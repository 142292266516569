const CompanyRegister = () => import("@/views/pages/register/Register.vue");

export const companyRegisterPage = {
  path: "/register",
  component: CompanyRegister,
  name: "PIEM - Cadastro de empresa",
  meta: {
    requiresAuth: false,
  },
};
