import { SET_USER, REMOVE_USER } from "@/store/modules/user/contracts";

export default {
  [SET_USER](state, payload) {
    Object.assign(state, payload);
  },
  [REMOVE_USER](state) {
    Object.assign(state, {
      accessToken: null,
      id: null,
      firstName: null,
      lastName: null,
      cnpj: null,
      cpf: null,
      isActive: null,
      email: null,
      cellphone: null,
      type: null,
      role: {
        name: null,
        type: null,
        permissions: [],
      },
      company: {
        id: null,
        status: null,
        mainUser: null,
      },
    });
  },
};
