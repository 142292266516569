export default {
  accessToken: null,
  id: null,
  firstName: null,
  lastName: null,
  cnpj: null,
  cpf: null,
  isActive: null,
  email: null,
  cellphone: null,
  type: null,
  role: {
    name: null,
    type: null,
    permissions: [],
  },
  company: {
    id: null,
    status: null,
    mainUser: null,
  },
};
