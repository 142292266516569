import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemPowerPlant extends BaseApiPiem {
  getAll(params) {
    return this.get(`/powerplant/all/${params}`);
  }

  getById(params) {
    return this.get(`/powerplant/by-id/${params}`);
  }

  getByClient(params) {
    return this.get(`/powerplant/by-client/${params}`);
  }

  getByFinalClient() {
    return this.get("/powerplant/by-final-client");
  }

  getByFinalClientEmail(email) {
    return this.get(`/powerplant/by-final-client-email/${email}`);
  }

  getWithoutClient() {
    return this.get("/powerplant/without-client");
  }

  create(data) {
    return this.post("/powerplant", data);
  }

  update(data, params) {
    return this.put(`/powerplant/id/${params}`, data);
  }

  updateClient(data, params) {
    return this.put(`/powerplant/update-client/${params}`, data);
  }

  remove(params) {
    return this.delete(`/powerplant/${params}`);
  }

  sharePowerPlants(data) {
    return this.put("/powerplant/share-power-plants", data);
  }
}

export const apiPiemPowerPlant = new ApiPiemPowerPlant();
