import { SET_CLIENT, RESET_CLIENT } from "@/store/modules/client/contracts";

export default {
  [SET_CLIENT](state, payload) {
    Object.assign(state, payload);
  },
  [RESET_CLIENT](state) {
    Object.assign(state, {
      _id: null,
      name: null,
      surname: null,
      email: null,
      cpf: null,
      cnpj: null,
      cellphone: null,
      powerPlants: [],
      consumerUnits: [],
      company: null,
    });
  },
};
