export const MONTHS = {
  JANUARY: "january",
  FEBRUARY: "february",
  MARCH: "march",
  APRIL: "april",
  MAY: "may",
  JUNE: "june",
  JULY: "july",
  AUGUST: "august",
  SEPTEMBER: "september",
  OCTOBER: "october",
  NOVEMBER: "november",
  DECEMBER: "december",
};
