import Main from "@/views/main/Main.vue";

export const privatePages = {
  path: "/",
  name: "Main",
  redirect: "/dashboard",
  component: Main,
  children: [
    {
      path: "/dashboard",
      name: "Visão geral",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiresAuth: true,
        key: "dashboard",
      },
    },
    {
      path: "/term-of-use",
      name: "Termo de uso",
      component: () => import("@/views/pages/termOfUse/TermOfUse.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/users/create",
      name: "Criacão de usuário",
      component: () => import("@/views/pages/users/CreateUser.vue"),
      meta: {
        requiresAuth: true,
        key: "piemAdmin",
      },
    },
    {
      path: "/user/edit/:id",
      name: "Edição de usuário",
      component: () => import("@/views/pages/users/EditUser.vue"),
      meta: {
        requiresAuth: true,
        key: "piemAdmin",
      },
    },
    {
      path: "/user/:id",
      name: "Usuário",
      component: () => import("@/views/pages/users/ProfileUser.vue"),
      meta: {
        requiresAuth: true,
        key: "piemAdmin",
      },
    },
    {
      path: "/me",
      name: "Meus Dados",
      component: () => import("@/views/pages/users/ProfileUser.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/roles",
      name: "Papéis",
      component: () => import("@/views/pages/roles/Roles.vue"),
      meta: {
        requiresAuth: true,
        key: "piemAdmin",
      },
    },
    {
      path: "/role/edit/:id",
      name: "Editar papel",
      component: () => import("@/views/pages/roles/EditRole.vue"),
      meta: {
        requiresAuth: true,
        key: "piemAdmin",
      },
    },
    {
      path: "/role/create",
      name: "Criação de papel",
      component: () => import("@/views/pages/roles/CreateRole.vue"),
      meta: {
        requiresAuth: true,
        key: "piemAdmin",
      },
    },
    {
      path: "/role/:id",
      name: "Visualização de papel",
      component: () => import("@/views/pages/roles/ViewRole.vue"),
      meta: {
        requiresAuth: true,
        key: "piemAdmin",
      },
    },
    {
      path: "/clients",
      name: "Clientes",
      component: () => import("@/views/pages/clients/Clients.vue"),
      meta: {
        requiresAuth: true,
        key: "client",
      },
    },
    {
      path: "/client/create",
      name: "Criação de cliente",
      component: () => import("@/views/pages/clients/CreateEditClient.vue"),
      meta: {
        requiresAuth: true,
        key: "client",
      },
    },
    {
      path: "/client/create/:powerPlantId",
      name: "Criação de Cliente por Usina",
      component: () => import("@/views/pages/clients/CreateEditClient.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/client/edit/:clientId",
      name: "Edição de cliente",
      component: () => import("@/views/pages/clients/CreateEditClient.vue"),
      meta: {
        requiresAuth: true,
        key: "client",
      },
    },
    {
      path: "/client/:id",
      name: "Cliente",
      component: () => import("@/views/pages/clients/Client.vue"),
      meta: {
        requiresAuth: true,
        key: "client",
      },
    },
    {
      path: "/client/view/:clientId",
      name: "Dashboard do cliente",
      component: () => import("@/views/pages/clients/ViewClient.vue"),
      meta: {
        requiresAuth: true,
        key: "client",
      },
    },
    {
      path: "/finalClients",
      name: "Cliente Final",
      component: () => import("@/views/pages/users/FinalClients.vue"),
      meta: {
        requiresAuth: true,
        key: "menuPieta",
      },
    },
    {
      path: "/companies",
      name: "Empresas",
      component: () => import("@/views/pages/company/Companies.vue"),
      meta: {
        requiresAuth: true,
        key: "company",
      },
    },
    {
      path: "/company/edit/:id",
      name: "Edição de Empresa",
      component: () => import("@/views/pages/company/EditCompany.vue"),
      meta: {
        requiresAuth: true,
        key: "company",
      },
    },
    {
      path: "/company/:id",
      name: "Visualização de empresa",
      component: () => import("@/views/pages/company/Company.vue"),
      meta: {
        requiresAuth: true,
        key: "company",
      },
    },
    {
      path: "/integrator-users",
      name: "Usuários Integradores",
      component: () => import("@/views/pages/users/Integrators.vue"),
      meta: {
        requiresAuth: true,
        key: "company",
      },
    },
    {
      path: "/admins",
      name: "Administradores",
      component: () => import("@/views/pages/users/Admins.vue"),
      meta: {
        requiresAuth: true,
        key: "company",
      },
    },
    {
      path: "/powerplants",
      name: "Usinas",
      component: () => import("@/views/pages/powerPlants/PowerPlants.vue"),
      meta: {
        requiresAuth: true,
        key: "powerPlant",
      },
    },
    {
      path: "/powerplant/create",
      name: "Adicionar Usina",
      component: () =>
        import("@/views/pages/powerPlants/CreateEditPowerPlant.vue"),
      meta: {
        requiresAuth: true,
        key: "powerPlant",
      },
    },
    {
      path: "/powerplant/create/:clientId",
      name: "Adicionar Usina por Cliente",
      component: () =>
        import("@/views/pages/powerPlants/CreateEditPowerPlant.vue"),
      meta: {
        requiresAuth: true,
        key: "powerPlant",
      },
    },
    {
      path: "/powerplant/edit/:powerPlantId",
      name: "Editar Usina",
      component: () =>
        import("@/views/pages/powerPlants/CreateEditPowerPlant.vue"),
      meta: {
        requiresAuth: true,
        key: "powerPlant",
      },
    },
    {
      path: "/powerplant/:id",
      name: "Usina",
      component: () => import("@/views/pages/powerPlants/ViewPowerPlant.vue"),
      meta: {
        requiresAuth: true,
        key: "powerPlant",
      },
    },
    {
      path: "/devices",
      name: "Dispositivos",
      component: () => import("@/views/pages/devices/Devices.vue"),
      meta: {
        requiresAuth: true,
        key: "device",
      },
    },
    {
      path: "/devices/create",
      name: "Adição de Dispositivo",
      component: () => import("@/views/pages/devices/CreateEditDevice.vue"),
      meta: {
        requiresAuth: true,
        key: "device",
      },
    },
    {
      path: "/devices/create/:clientId?/:powerPlantId?/:ucId?",
      name: "Adicionar Dispositivo por Cliente",
      component: () => import("@/views/pages/devices/CreateEditDevice.vue"),
      meta: {
        requiresAuth: true,
        key: "device",
      },
    },
    {
      path: "/devices/edit/:deviceId",
      name: "Edição de Dispositivo",
      component: () => import("@/views/pages/devices/CreateEditDevice.vue"),
      meta: {
        requiresAuth: true,
        key: "device",
      },
    },
    {
      path: "/devices/:id",
      name: "Dispositivo",
      component: () => import("@/views/pages/devices/Device.vue"),
      meta: {
        requiresAuth: true,
        key: "device",
      },
    },
    {
      path: "/settings/alerts",
      name: "Configurações de Alertas",
      component: () => import("@/views/pages/settingsIntegrator/Alerts.vue"),
      meta: {
        requiresAuth: true,
        key: "menuSettingsIntegrator",
      },
    },
  ],
};
