import axios from "axios";
import store from "@/store";
import { environmentVariables } from "@/plugins/environment-variables";

/**
 @abstract
*/
export class BaseApiPiem {
  constructor() {
    this.instance = axios.create({
      baseURL: environmentVariables.backend_url,
    });
  }

  _getHeader() {
    const accessToken = store.getters["user/getAccessToken"];
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  get(url) {
    return this._requestHandler({ url, method: "get" });
  }

  delete(url) {
    return this._requestHandler({ url, method: "delete" });
  }

  put(url, data) {
    return this._requestHandler({ url, data, method: "put" });
  }

  post(url, data) {
    return this._requestHandler({ url, data, method: "post" });
  }

  patch(url, data) {
    return this._requestHandler({ url, data, method: "patch" });
  }

  async _requestHandler(payload) {
    try {
      const { method, url, data } = payload;
      let ret = null;
      store.commit("loading/UPDATE_LOADING", true);
      switch (method) {
        case "get":
        case "delete":
          ret = await this.instance[method](url, {
            headers: this._getHeader(),
          });
          break;
        case "put":
        case "post":
        case "patch":
          ret = await this.instance[method](url, data, {
            headers: this._getHeader(),
          });
          break;
        default:
          ret = {
            data: null,
            status: 400,
          };
          break;
      }
      store.commit("loading/UPDATE_LOADING", false);
      return ret;
    } catch (error) {
      store.commit("loading/UPDATE_LOADING", false);
      const errorStatus = error?.response?.status;
      if (errorStatus === 401 && !!store.getters["user/getAccessToken"]) {
        await store.dispatch("user/logout");
      }
      return error?.response;
    }
  }
}
