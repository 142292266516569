<template>
  <v-navigation-drawer
    :permanent="!isMobileBreakpoint"
    width="100%"
    fixed
    app
    floating
    v-model="isMenuOpen"
    :right="$vuetify.rtl"
    :class="
      isMobileBreakpoint ? 'border-radius-lg bg-white' : 'border-radius-lg'
    "
    data-theme="transparent"
    :mini-variant.sync="isMenuMini"
    mini-variant-width="90px"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-list-item-content class="pa-0">
          <v-list-item-title>
            <div class="v-navigation-drawer-brand d-flex align-center pb-2">
              <v-img
                src="@/assets/img/piem/logo.svg"
                position
                class="shrink"
                contain
                width="210"
              ></v-img>
              <v-btn
                v-if="isMobileBreakpoint"
                icon
                :ripple="false"
                @click="isMenuOpen = !isMenuOpen"
              >
                <v-icon size="18" color="white">$close</v-icon>
              </v-btn>
            </div>
          </v-list-item-title>
        </v-list-item-content>

        <hr class="piem-menu-hr" />

        <v-list nav dense class="my-4">
          <v-list-item-group>
            <div v-for="(item, i) in items" :key="i">
              <v-list-item
                link
                :to="item.link"
                class="pb-1 mx-2 no-default-hover piem-menu"
                :ripple="false"
                active-class="item-active"
                v-if="!item.external"
              >
                <v-list-item-icon
                  class="
                    shadow
                    border-radius-md
                    mx-2
                    align-center
                    piem-menu-item
                  "
                >
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="ms-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                :href="item.link"
                class="pb-1 mx-2 no-default-hover"
                :ripple="false"
                active-class="item-active"
                v-if="item.external"
                target="_blank"
              >
                <v-list-item-icon
                  class="shadow border-radius-md mx-2 align-center"
                >
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="ms-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-item-group>
          <v-list-group
            :ripple="false"
            v-for="item in itemsPages"
            :key="item.title"
            v-model="item.active"
            :append-icon="ICONS.ANGLE_DOWN"
            class="pb-1 mx-2"
            active-class="item-active"
          >
            <template v-slot:activator>
              <v-list-item-icon
                class="shadow border-radius-md mx-2 align-center"
              >
                <v-icon> {{ item.icon }} </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :ripple="false"
              link
              class="mb-0 no-default-hover"
              :class="child.active ? 'item-active' : ''"
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
            >
              <span class="v-list-item-mini" v-text="child.prefix"></span>

              <v-list-item-content class="ms-6 ps-7" v-if="!child.items">
                <v-list-item-title @click="listClose($event)">
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ms-6 ps-7 py-0" v-if="child.items">
                <v-list-group
                  prepend-icon=""
                  :ripple="false"
                  sub-group
                  no-action
                  v-model="child.active"
                >
                  <template v-slot:activator>
                    <span class="v-list-item-mini">{{ child.prefix }}</span>
                    <v-list nav dense class="pa-0">
                      <v-list-group
                        :ripple="false"
                        :append-icon="ICONS.ANGLE_DOWN"
                        active-class="item-active"
                        class="mb-0"
                      >
                        <template v-slot:activator>
                          <v-list-item-content class="mb-0 py-0">
                            <v-list-item-title
                              {{
                              child.title
                              }}
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-group>
                    </v-list>
                  </template>

                  <v-list-item
                    v-for="child2 in child.items"
                    :ripple="false"
                    :key="child2.title"
                    :to="child2.link"
                    @click="listClose($event)"
                  >
                    <v-list-item-content>
                      <span class="v-list-item-mini">
                        {{ child2.prefix }}
                      </span>
                      <v-list-item-title>
                        {{ child2.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <hr class="piem-menu-hr" />
      </v-col>
      <v-col cols="12" class="menu-pieta__container">
            <div class="menu-pieta__text">UMA PLATAFORMA</div>
            <v-img
              position
              class="shrink"
              contain
              max-width="38"
              max-height="39"
              src="@/assets/img/piem/logo-footer-white.svg"
            />
          </v-col>
    </v-row>
  </v-navigation-drawer>
</template>
<script>
import { menuDrawer } from "@/components/menuList/MenuList";
import { ICONS } from "@/utils";
export default {
  name: "Menu",
  props: {
    menu: {
      type: Boolean,
      default: null,
    },
    miniMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ICONS,
      items: [],
      itemsPages: [],
    };
  },

  computed: {
    isMenuOpen: {
      get() {
        return this.menu;
      },
      set(value) {
        this.$emit("openHideMenu", value);
      },
    },

    isMenuMini: {
      get() {
        return this.miniMenu;
      },
      set(value) {
        this.$emit("openHideMenuMini", value);
      },
    },

    isMobileBreakpoint() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  mounted() {
    this.items = menuDrawer("menu");
    this.itemsPages = menuDrawer("submenu");
  },

  methods: {
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>
<style scoped>
.menu-pieta__container {
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu-pieta__text {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.06px;
  color: white;
  margin-bottom: 5px;
}
</style>
