import axios from "axios";

class ApiViaCep {
  constructor() {
    this.instance = axios.create({
      baseURL: "https://viacep.com.br/ws/",
    });
  }

  async getCep(cep) {
    try {
      if (cep?.length === 9) {
        const response = await this.instance.get(`${cep}/json/`);
        if (response?.status === 200 && response.data)
          return {
            success: true,
            data: {
              street: response?.data?.logradouro || null,
              neighborhood: response?.data?.bairro || null,
              state: response?.data?.uf || null,
              city: response?.data?.localidade || null,
            },
          };
        return { success: false, data: null };
      }
    } catch (e) {
      console.error(e);
      return { success: false, data: null };
    }
  }
}
export const API_VIA_CEP = new ApiViaCep();
