import { BaseApiPiem } from "./BaseApiPiem";

class ApiPiemRole extends BaseApiPiem {
  getAll(params) {
    return this.get(`/roles/all/${params}`);
  }

  getList() {
    return this.get("/roles/list/");
  }

  getDetails(params) {
    return this.get(`/roles/details/${params}`);
  }

  getById(params) {
    return this.get(`/roles/by-id/${params}`);
  }

  getByClient(params) {
    return this.get(`/roles/by-client/${params}`);
  }

  getByFinalClient() {
    return this.get("/roles/by-final-client");
  }

  getWithoutClient() {
    return this.get("/roles/without-client");
  }

  create(data) {
    return this.post("/roles", data);
  }

  update(data, params) {
    return this.put(`/roles/${params}`, data);
  }

  remove(params) {
    return this.delete(`/roles/${params}`);
  }
}

export const apiPiemRole = new ApiPiemRole();
